<ion-app>
  <app-header></app-header>
  <ion-content fullscreen>
    <div id='content-container'>
      <app-task-list *ngIf='auth.signedInUser$ | async; else sign_in'></app-task-list>
      <ng-template #sign_in>
        <app-sign-in>
        </app-sign-in>
      </ng-template>
    </div>
  </ion-content>
</ion-app>