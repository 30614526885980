<ion-header mode='md'>
    <ion-toolbar>
        <ion-title color='primary'>TODO</ion-title>
        <ion-chip slot='end' *ngIf='auth.signedInUser$ | async as user' (click)='signOut()'>
            <ion-avatar>
                <img [src]='user.photoURL' />
            </ion-avatar>
            <ion-label>{{ user.displayName }}</ion-label>
            <ion-icon name="log-out-sharp"></ion-icon>
        </ion-chip>
    </ion-toolbar>
</ion-header>